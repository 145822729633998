/* index.js */

/** External Module Dependencies **/
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Web3Provider } from "@ethersproject/providers";
import { Web3ReactProvider } from "@web3-react/core";
import { BrowserRouter } from "react-router-dom";

/** Global Module Dependencies **/
import * as serviceWorker from "./serviceWorker";
import AppInitialState from "./app-state/app-initial-state";

/** Internal Module Dependencies **/
import configureStore from "./store/store";
import App from "./App";

function getLibrary(provider, connector) {
	const library = new Web3Provider(provider);
	library.pollingInterval = 12000;

	return library;
}

ReactDOM.render(
	<Provider store={configureStore(AppInitialState)}>
		<BrowserRouter>
			<Web3ReactProvider getLibrary={getLibrary}>
				<App />
			</Web3ReactProvider>
		</BrowserRouter>
	</Provider>,
	document.getElementById("root")
);

serviceWorker.unregister();
